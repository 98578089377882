<template>
  <div class="body vsc-initialized" cz-shortcut-listen="true">
    <div>
      <p
        style="
          margin-bottom: 0pt;
          text-align: justify;
          line-height: normal;
          font-size: 24pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >Politique de confidentialité</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Dernière mise à jour le 25 Avril 2024</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Merci d'avoir choisi de faire partie de notre communauté à l’</span
        ><span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >Agence nationale pour l'Emploi</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
        >
          (AnpE). Nous nous engageons à protéger vos informations personnelles et votre
          droit à la vie privée. Si vous avez des questions ou des préoccupations
          concernant notre politique ou nos pratiques concernant vos informations
          personnelles, veuillez nous envoyer votre préoccupation sur</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp; </span
        ><span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >directiongenerale</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >@</span
        ><span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >anpe</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >.bj</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Lorsque vous visitez notre site Web</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><strong
          ><u
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
              >https://anpe.bj/</span
            ></u
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >et utilisez nos services, vous nous confiez vos informations personnelles. Nous
          prenons votre vie privée très au sérieux. Dans cette politique de
          confidentialité, nous cherchons à vous expliquer de la manière la plus claire
          possible quelles informations nous collectons, comment nous les utilisons et
          quels droits vous avez à leur égard. Nous espérons que vous prendrez le temps de
          le lire attentivement, car il est important. S'il y a des conditions dans cette
          politique de confidentialité avec lesquelles vous n'êtes pas d'accord, veuillez
          cesser d'utiliser nos sites et nos services.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Cette politique de confidentialité s'applique à toutes les informations
          collectées via notre site Web (tel que</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><strong
          ><u
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
              >https://anpe.bj/</span
            ></u
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >) et/ou à tout service (nous les désignons collectivement dans cette politique
          de confidentialité sous le nom de «</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Prestations de service</span
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >").</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Veuillez lire attentivement cette politique de confidentialité car elle vous
            aidera à prendre des décisions éclairées concernant le partage de vos
            informations personnelles avec nous.</span
          ></strong
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 13.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >TABLE DES MATIÈRES</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#infocollect" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >1. QUELLES INFORMATIONS COLLECTONS-NOUS</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#infouse" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >2. COMMENT UTILISONS-NOUS VOS INFORMATIONS</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#infoshare" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >3. VOS INFORMATIONS SERONT-ELLES PARTAGÉES AVEC QUICONQUE</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#whoshare" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >4. AVEC QUI VOS INFORMATIONS SERONT-ELLES PARTAGÉES</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#cookies" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >5. UTILISONS-NOUS DES COOKIES ET AUTRES TECHNOLOGIES DE SUIVI</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#googlemaps" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >6. UTILISONS-NOUS GOOGLE MAPS</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#inforetain" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >7. COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS ?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#infosafe" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >8. COMMENT GARDONS-NOUS VOS INFORMATIONS EN SÉCURITÉ</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#infominors" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >9. COLLECTONS-NOUS DES INFORMATIONS SUR LES MINEURS</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#privacyrights" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >10. QUELS SONT VOS DROITS À LA VIE PRIVÉE</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#databreach" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >11. VIOLATION DE DONNÉES</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#DNT" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >12. COMMANDES POUR LES FONCTIONS NE PAS SUIVI</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#caresidents" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >13. LES RÉSIDENTS DE CALIFORNIE ONT-ILS DES DROITS SPÉCIFIQUES À LA VIE
                PRIVÉE</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#policyupdates" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >14. Devons-nous mettre à jour cette politique</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <a href="#contact" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >15. COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CETTE POLITIQUE</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >&nbsp;</span
              ></u
            ></strong
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >?</span
              ></u
            ></strong
          ></a
        >
      </p>
      <div>
        <hr style="width: 700px; height: 1.5pt; margin-left: 0; text-align: justify" />
      </div>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >1. QUELLES INFORMATIONS COLLECTONS-NOUS</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Informations personnelles que vous nous divulguez</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Nous collectons les informations personnelles que vous nous fournissez.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous collectons les informations personnelles que vous nous fournissez
          volontairement lorsque vous exprimez votre intérêt à obtenir des informations
          sur nous ou sur nos produits et services, lorsque vous participez à des
          activités sur les Services ou lorsque vous nous contactez de toute autre
          manière.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Les informations personnelles que nous collectons dépendent du contexte de vos
          interactions avec nous et les Services, des choix que vous faites et des
          produits et fonctionnalités que vous utilisez. Les informations personnelles que
          nous collectons peuvent inclure les éléments suivants</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >:</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Informations personnelles accessibles au public.</span
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous collectons le prénom, le nom de jeune fille, le nom et le surnom</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >; adresses mail; courrier électronique professionnel</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >; adresse actuelle et ancienne</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >; et d'autres données similaires.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Toutes les informations personnelles que vous nous fournissez doivent être
          véridiques, complètes et exactes, et vous devez nous informer de toute
          modification apportée à ces informations personnelles.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <br /><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Informations collectées automatiquement</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Certaines informations, telles que l'adresse IP et/ou les caractéristiques du
            navigateur et de l'appareil, sont collectées automatiquement lorsque vous
            visitez nos Services.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous collectons automatiquement certaines informations lorsque vous visitez,
          utilisez ou naviguez sur les Services. Ces informations ne révèlent pas votre
          identité spécifique (comme votre nom ou vos coordonnées) mais peuvent inclure
          des informations sur l'appareil et son utilisation, telles que votre adresse IP,
          les caractéristiques de votre navigateur et de votre appareil, votre système
          d'exploitation, vos préférences linguistiques, les URL de référence, le nom de
          l'appareil, votre pays, votre emplacement. , des informations sur comment et
          quand vous utilisez nos services et d'autres informations techniques. Ces
          informations sont principalement nécessaires pour maintenir la sécurité et le
          fonctionnement de nos services, ainsi qu'à des fins d'analyse et de reporting
          internes.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Comme de nombreuses entreprises, nous collectons également des informations via
          des cookies et des technologies similaires.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Identifiants en ligne.</span
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous collectons des appareils</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >; des outils et des protocoles, tels que les adresses IP (Internet
          Protocol)</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >; identifiants de cookies, ou autres tels que ceux utilisés à des fins
          d'analyse et de marketing</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >; et d'autres données similaires.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <br /><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Informations collectées à partir d'autres sources</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >nous pouvons collecter des données limitées à partir de bases de données
            publiques, de partenaires</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;
          </span></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >et d'autres sources externes.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous pouvons obtenir des informations vous concernant à partir d'autres
          sources, telles que des bases de données publiques, des partenaires, ainsi que
          d'autres tiers. Des exemples d'informations que nous recevons d'autres sources
          comprennent : les informations de profil sur les réseaux sociaux ; les pistes,
          les résultats et les liens de recherche, y compris les listes payantes (telles
          que les liens sponsorisés). Nous vous informerons de la source des informations
          ainsi que du type d'informations et du type d'informations que nous avons
          collectées à votre sujet dans un délai raisonnable après l'obtention des données
          personnelles, mais au plus tard dans un délai d'un mois.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >2. COMMENT UTILISONS-NOUS VOS INFORMATIONS</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Nous traitons vos informations à des fins basées sur des intérêts légitimes
            tels que votre insertion professionnelle, l'exécution de notre contrat avec
            vous, le respect de nos obligations légales et/ou votre consentement.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous utilisons les informations personnelles collectées via nos services à
          diverses fins</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp; </span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >décrites ci-dessous. Nous traitons vos informations personnelles à ces fins en
          nous appuyant sur nos intérêts légitimes, afin de conclure ou d'exécuter un
          contrat avec vous, avec votre consentement, et/ou pour nous conformer à nos
          obligations légales. Nous indiquons les motifs de traitement spécifiques sur
          lesquels nous nous appuyons à côté de chaque finalité énumérée ci-dessous.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous utilisons les informations que nous collectons ou recevons:</span
        ><br /><br /><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Pour vous envoyer les offres d’emploi disponibles.</span
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous et/ou nos partenaires tiers pouvons utiliser les informations personnelles
          que vous nous envoyez à nos fins d’insertion professionnelle, si cela est
          conforme à votre profil. Vous pouvez vous désinscrire de nos e-mails marketing à
          tout moment (voir «</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><a
          href="https://app.termly.io/dashboard/website/389150/privacy-policy#privacyrights"
          style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >QUELS SONT VOS DROITS À LA CONFIDENTIALITÉ</span
              ></u
            ></strong
          ></a
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >» ci-dessous).</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Vous proposez des offres ciblées.</span
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous pouvons utiliser vos informations pour développer et afficher du contenu
          et des publications (et travailler avec des tiers qui le font) adaptés à vos
          intérêts et/ou à votre emplacement et pour mesurer leur efficacité.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Pour répondre aux demandes des utilisateurs/offrir une assistance aux
            utilisateurs.</span
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous pouvons utiliser vos informations pour répondre à vos demandes et résoudre
          tout problème potentiel que vous pourriez rencontrer lors de l'utilisation de
          nos services.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >À d’autres fins d’insertions professionnelles.</span
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous pouvons utiliser vos informations à d'autres fins d’insertions, telles que
          l'analyse de données, l'identification des tendances d'utilisation, la
          détermination de l'efficacité de nos campagnes promotionnelles et l'évaluation
          et l'amélioration de nos services, produits et votre expérience. Nous pouvons
          utiliser et stocker ces informations sous forme agrégée et anonymisée afin
          qu'elles ne soient pas associées à des utilisateurs finaux individuels et
          n'incluent pas d'informations personnelles. Nous n'utiliserons pas
          d'informations personnelles identifiables sans votre consentement.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >3. VOS INFORMATIONS SERONT-ELLES PARTAGÉES AVEC QUICONQUE</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >nous partageons des informations uniquement avec votre consentement, pour
            nous conformer aux lois, pour vous fournir des services, pour protéger vos
            droits ou pour remplir nos obligations commerciales.</span
          ></em
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous pouvons traiter ou partager des données sur la base de la base juridique
          suivante</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >:</span
        >
      </p>
      <ul style="margin: 0pt; padding-left: 0pt">
        <li
          style="
            margin-top: 14pt;
            margin-left: 9.6pt;
            margin-bottom: 12pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Consentement</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >&nbsp;</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >:</span
            ></strong
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >Nous pouvons traiter vos données si vous nous avez donné votre consentement
            spécifique pour utiliser vos informations personnelles dans un but
            spécifique.</span
          >
        </li>
        <li
          style="
            margin-left: 9.6pt;
            margin-bottom: 12pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Intérêts légitimes</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >&nbsp;</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >:</span
            ></strong
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >Nous pouvons traiter vos données lorsque cela est raisonnablement nécessaire
            pour atteindre nos intérêts commerciaux légitimes.</span
          >
        </li>
        <li
          style="
            margin-left: 9.6pt;
            margin-bottom: 12pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Exécution d'un contrat</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >&nbsp;</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >:</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >&nbsp;</span
            ></strong
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >lorsque nous avons conclu un contrat avec vous, nous pouvons traiter vos
            informations personnelles pour remplir les termes de notre contrat.</span
          >
        </li>
        <li
          style="
            margin-left: 9.6pt;
            margin-bottom: 12pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Obligations légales</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >&nbsp;</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >:</span
            ></strong
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >Nous pouvons divulguer vos informations lorsque nous sommes légalement tenus
            de le faire afin de nous conformer à la loi applicable, aux demandes
            gouvernementales, à une procédure judiciaire, à une ordonnance d'un tribunal
            ou à une procédure judiciaire, par exemple en réponse à une ordonnance d'un
            tribunal ou à une assignation à comparaître ( y compris en réponse aux
            autorités publiques pour répondre aux exigences de sécurité nationale ou
            d’application de la loi).</span
          >
        </li>
        <li
          style="
            margin-left: 9.6pt;
            margin-bottom: 3pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Intérêts vitaux</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >&nbsp;</span
            ></strong
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >:</span
            ></strong
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >nous pouvons divulguer vos informations lorsque nous pensons que cela est
            nécessaire pour enquêter, prévenir ou prendre des mesures concernant des
            violations potentielles de nos politiques, des fraudes présumées, des
            situations impliquant des menaces potentielles pour la sécurité de toute
            personne et des activités illégales, ou comme preuve dans litiges dans
            lesquels nous sommes impliqués.</span
          >
        </li>
      </ul>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Plus précisément, nous pouvons être amenés à traiter vos données ou à partager
          vos informations personnelles dans les situations suivantes :</span
        >
      </p>
      <ul style="margin: 0pt; padding-left: 0pt">
        <li
          style="
            margin-top: 14pt;
            margin-left: 9.6pt;
            margin-bottom: 12pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Employeurs, demandeurs d’emploi, consultants et autres prestataires de
              services tiers.</span
            ></strong
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >Nous pouvons partager vos données avec des employeurs tiers, des demandeurs
            d’emploi, des prestataires de services, des sous-traitants ou des agents qui
            fournissent des services pour nous ou en notre nom et qui ont besoin d'accéder
            à ces informations pour effectuer ce travail. Nous pouvons autoriser des tiers
            sélectionnés à utiliser une technologie de suivi sur les Services, ce qui leur
            permettra de collecter des données sur la façon dont vous interagissez avec
            les Services au fil du temps. Ces informations peuvent être utilisées, entre
            autres, pour analyser et suivre des données, déterminer la popularité de
            certains contenus et mieux comprendre l'activité en ligne. Sauf indication
            contraire dans la présente politique, nous ne partageons, vendons, louons ou
            n'échangeons aucune de vos informations avec des tiers à des fins
            promotionnelles. Nous avons des contrats en place avec nos sous-traitants.
            Cela signifie qu’ils ne peuvent rien faire avec vos informations personnelles
            à moins que nous ne leur ayons demandé de le faire. Ils ne partageront vos
            informations personnelles avec aucune organisation autre que nous. Ils le
            tiendront en toute sécurité et le conserveront pendant la période que nous
            leur indiquons.</span
          >
        </li>
        <li
          style="
            margin-left: 9.6pt;
            margin-bottom: 3pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Transferts d'entreprises.</span
            ></strong
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >Nous pouvons partager ou transférer vos informations dans le cadre ou pendant
            les recrutements, financement ou acquisition de tout ou partie de notre
            entreprise à une autre société.</span
          >
        </li>
      </ul>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >4. AVEC QUI VOS INFORMATIONS SERONT-ELLES PARTAGÉES</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >nous partageons uniquement des informations avec les tiers suivants.</span
          ></em
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous partageons et divulguons vos informations uniquement avec les tiers
          suivants. Nous avons classé chaque partie afin que vous puissiez facilement
          comprendre le but de nos pratiques de collecte et de traitement des données. Si
          nous avons traité vos données sur la base de votre consentement et que vous
          souhaitez révoquer votre consentement, veuillez nous contacter.</span
        >
      </p>
      <ul style="margin: 0pt; padding-left: 0pt">
        <li
          style="
            margin-top: 14pt;
            margin-left: 9.6pt;
            margin-bottom: 3pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <br /><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >Autoriser les utilisateurs à se connecter à leur compte WordPress</span
          ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >&nbsp;</span
          ><strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >de comptes tiers</span
            ></strong
          >
        </li>
      </ul>
      <ul style="margin: 0pt; padding-left: 0pt">
        <li
          style="
            margin-left: 9.6pt;
            margin-bottom: 3pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Optimisation du contenu</span
            ></strong
          ><br /><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >Google Site Search et intégration de vidéos YouTube</span
          >
        </li>
      </ul>
      <ul style="margin: 0pt; padding-left: 0pt">
        <li
          style="
            margin-left: 9.6pt;
            margin-bottom: 3pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Enregistrement et authentification du compte utilisateur</span
            ></strong
          ><br /><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >WordPress Single Sign On</span
          >
        </li>
      </ul>
      <ul style="margin: 0pt; padding-left: 0pt">
        <li
          style="
            margin-left: 9.6pt;
            margin-bottom: 3pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >AnalyseWeb et mobile</span
            ></strong
          ><br /><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >Google Analytics</span
          ><br /><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >Google Ads</span
          >
        </li>
      </ul>
      <ul style="margin: 0pt; padding-left: 0pt">
        <li
          style="
            margin-left: 9.6pt;
            margin-bottom: 3pt;
            text-align: justify;
            line-height: normal;
            padding-left: 8.4pt;
            font-family: serif;
            font-size: 10pt;
            color: #4f4e56;
            background-color: #ffffff;
          "
        >
          <strong
            ><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt; "
              >Hébergement de sites Web</span
            ></strong
          ><br /><span style="font-family:&#39;Times New Roman&#39;; font-size:12pt"
            >WordPress.com</span
          >
        </li>
      </ul>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >5. UTILISONS-NOUS DES COOKIES ET AUTRES TECHNOLOGIES DE SUIVI</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >nous pouvons utiliser des cookies et d'autres technologies de suivi pour
            collecter et stocker vos informations.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous pouvons utiliser des cookies et des technologies de suivi similaires
          (comme des balises Web et des pixels) pour accéder ou stocker des informations.
          Des informations spécifiques sur la manière dont nous utilisons ces technologies
          et sur la manière dont vous pouvez refuser certains cookies sont définies dans
          notre</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><a href="#" target="_blank" style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >Politique en matière de cookies</span
              ></u
            ></strong
          ></a
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >6. UTILISONS-NOUS GOOGLE MAPS</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref :</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Oui, nous utilisons Google Maps dans le but de fournir un meilleur
            service.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Ce site Web, application mobile ou application Facebook utilise les API Google
          Maps. Vous pouvez trouver les conditions d'utilisation des API Google Maps</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><a
          href="https://developers.google.com/maps/terms"
          target="_blank"
          style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >ici</span
              ></u
            ></strong
          ></a
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >. Pour mieux comprendre la politique de confidentialité de Google,
          veuillez-vous référer à ce</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><a
          href="https://policies.google.com/privacy"
          target="_blank"
          style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >lien</span
              ></u
            ></strong
          ></a
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >En utilisant notre implémentation de l'API Maps, vous acceptez d'être lié par
          les conditions d'utilisation de Google.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >7. COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS ?</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >nous conservons vos informations aussi longtemps que nécessaire pour
            atteindre les objectifs décrits dans cette politique de confidentialité, sauf
            disposition contraire de la loi.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous ne conserverons vos informations personnelles que le temps nécessaire aux
          fins énoncées dans la présente politique de confidentialité, à moins qu'une
          période de conservation plus longue ne soit requise ou autorisée par la loi
          (telle que la fiscalité, la comptabilité ou d'autres exigences légales). Aucun
          objectif de cette politique ne nous obligera à conserver vos informations
          personnelles pendant plus de 2 ans.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Lorsque nous n'avons aucun besoin professionnel légitime de traiter vos
          informations personnelles, nous les supprimerons ou les anonymiserons, ou, si
          cela n'est pas possible (par exemple, parce que vos informations personnelles
          ont été stockées dans des archives de sauvegarde), nous les stockerons en toute
          sécurité. Vos informations personnelles et les isoler de tout traitement
          ultérieur jusqu'à ce que leur suppression soit possible.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >8. COMMENT GARDONS-NOUS VOS INFORMATIONS EN SÉCURITÉ</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Nous visons à protéger vos informations personnelles grâce à un système de
            mesures de sécurité organisationnelles et techniques.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous avons mis en œuvre des mesures de sécurité techniques et
          organisationnelles appropriées conçues pour protéger la sécurité de toutes les
          informations personnelles que nous traitons. Cependant, n’oubliez pas que nous
          ne pouvons pas garantir qu’Internet lui-même soit sécurisé à 100 %. Bien que
          nous fassions de notre mieux pour protéger vos informations personnelles, la
          transmission des informations personnelles vers et depuis nos Services se fait à
          vos propres risques. Vous ne devez accéder aux services que dans un
          environnement sécurisé.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >9. COLLECTONS-NOUS DES INFORMATIONS SUR LES MINEURS</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >nous ne collectons pas sciemment de données auprès d'enfants de moins de
            18</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >ans et ne les commercialisons pas auprès d'eux.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous ne sollicitons pas sciemment de données auprès d'enfants de moins de 18
          ans et ne les commercialisons pas. En utilisant les Services, vous déclarez que
          vous avez au moins 18 ans ou que vous êtes le parent ou le tuteur d'un tel
          mineur et consentez à ce que cette personne mineure à charge utilise les
          Services. Si nous apprenons que des informations personnelles d'utilisateurs de
          moins de 18 ans ont été collectées, nous désactiverons le compte et prendrons
          des mesures raisonnables pour supprimer rapidement ces données de nos dossiers.
          Si vous avez connaissance de données que nous avons collectées auprès d'enfants
          de moins de 18 ans, veuillez nous envoyer votre préoccupation sur</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp; </span
        ><span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >directiongenerale</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >@</span
        ><span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >anpe</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >.bj</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >10. QUELS SONT VOS DROITS À LA VIE PRIVÉE</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >dans certaines régions, comme l'Espace économique européen, vous disposez de
            droits qui vous permettent un meilleur accès et un meilleur contrôle sur vos
            informations personnelles. Vous pouvez consulter, modifier ou résilier votre
            compte à tout moment.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Dans certaines régions (comme l'Espace économique européen), vous disposez de
          certains droits en vertu des lois applicables en matière de protection des
          données. Ceux-ci peuvent inclure le droit (i) de demander l’accès et d’obtenir
          une copie de vos informations personnelles, (ii) de demander la rectification ou
          l’effacement ; (iii) pour restreindre le traitement de vos informations
          personnelles</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >; et (iv) le cas échéant, à la portabilité des données. Dans certaines
          circonstances, vous pouvez également avoir le droit de vous opposer au
          traitement de vos informations personnelles. Pour faire une telle demande,
          veuillez utiliser les</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><a
          href="https://app.termly.io/dashboard/website/389150/privacy-policy#contact"
          style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >coordonnées</span
              ></u
            ></strong
          ></a
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >fournies ci-dessous. Nous examinerons et répondrons à toute demande
          conformément aux lois applicables en matière de protection des données.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Si nous comptons sur votre consentement pour traiter vos informations
          personnelles, vous avez le droit de retirer votre consentement à tout moment.
          Veuillez toutefois noter que cela n'affectera pas la licéité du traitement avant
          son retrait.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Si vous résidez dans l'Espace économique africain et que vous pensez que nous
          traitons illégalement vos informations personnelles, vous avez également le
          droit de porter plainte auprès de votre autorité locale de contrôle de la
          protection des données. Vous pouvez trouver leurs coordonnées ici :</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><strong
          ><u
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
              >http://apdp.bj</span
            ></u
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Cookies et technologies similaires :</span
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >La plupart des navigateurs Web sont configurés pour accepter les cookies par
          défaut. Si vous préférez, vous pouvez généralement choisir de configurer votre
          navigateur pour supprimer les cookies et les rejeter. Si vous choisissez de
          supprimer les cookies ou de refuser les cookies, cela pourrait affecter
          certaines fonctionnalités ou services de nos Services. Pour vous désinscrire de
          la publicité basée sur les centres d'intérêt des annonceurs sur nos services,
          visitez</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><a
          href="http://www.aboutads.info/choices/"
          target="_blank"
          style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >http://www.aboutads.info/choices/</span
              ></u
            ></strong
          ></a
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >11. VIOLATION DE DONNÉES</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Une atteinte à la vie privée se produit lorsqu’il y a accès non autorisé à des
          renseignements personnels ou lorsqu’ils sont collectés, utilisés, divulgués ou
          éliminés. Vous serez informé des violations de données lorsque l</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #262626;
            background-color: #fcfcfc;
          "
          >’Autorité de Protection des Données à caractère Personnel (APDP)</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
        >
          estime que vous êtes susceptible de courir un risque ou de subir un préjudice
          grave. Par exemple, une violation de données peut entraîner de graves préjudices
          financiers ou nuire à votre bien-être mental ou physique. Dans le cas où
          l’</span
        ><span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >Agence nationale pour l'emploi</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
        >
          (ANPE) aurait connaissance d'une faille de sécurité ayant entraîné ou pouvant
          entraîner un accès, une utilisation ou une divulgation non autorisés
          d'informations personnelles, l'</span
        ><span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >Agence nationale pour l'emploi</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
        >
          (AnpE) enquêtera rapidement sur l'affaire et en informera l'autorité de
          surveillance compétente. Autorité au plus tard 72 heures après en avoir pris
          connaissance, à moins que la violation de données à caractère personnel ne soit
          pas susceptible d'entraîner un risque pour les droits et libertés des personnes
          physiques.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >12. COMMANDES POUR LES FONCTIONS NE PAS SUIVI</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >La plupart des navigateurs Web et certains systèmes d'exploitation mobiles et
          applications mobiles incluent une fonctionnalité ou un paramètre Do-Not-Track («
          DNT ») que vous pouvez activer pour signaler votre préférence en matière de
          confidentialité afin que les données sur vos activités de navigation en ligne ne
          soient pas surveillées et collectées. Aucune norme technologique uniforme pour
          la reconnaissance et la mise en œuvre des signaux DNT n'a été finalisée. En tant
          que tel, nous ne répondons pas actuellement aux signaux du navigateur DNT ou à
          tout autre mécanisme communiquant automatiquement votre choix de ne pas être
          suivi en ligne. Si une norme de suivi en ligne est adoptée et que nous devons la
          suivre à l'avenir, nous vous informerons de cette pratique dans une version
          révisée de cette politique de confidentialité.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >13. LES RÉSIDENTS DE BENIN ONT-ILS DES DROITS SPÉCIFIQUES À LA VIE
            PRIVÉE</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Oui, si vous résidez au Bénin, vous bénéficiez de droits spécifiques
            concernant l'accès à vos informations personnelles.</span
          ></em
        >
      </p>
      <p class="NoSpacing" style="text-align: justify; font-size: 12pt">
        <span style="font-family:&#39;Times New Roman&#39;"
          >Vos données personnelles sont confidentielles et ne seront communiquées qu’aux
          fins pour lesquelles elles ont été collectées et seulement par les entités
          publiques béninoises et leurs partenaires. Toute communication de vos données
          personnelles à des tiers n’est faite qu’en cas de nécessité et dans le strict
          respect de la légalité.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Si vous avez moins de 18 ans, résidez en Bénin et disposez d'un compte
          enregistré auprès des Services, vous avez le droit de demander la suppression
          des données indésirables que vous publiez publiquement sur les Services. Pour
          demander la suppression de ces données, veuillez nous contacter en utilisant les
          informations de contact fournies ci-dessous et inclure l'adresse e-mail associée
          à votre compte et une déclaration indiquant que vous résidez en Bénin. Nous
          veillerons à ce que les données ne soient pas affichées publiquement sur les
          Services, mais sachez que les données ne peuvent pas être complètement ou
          totalement supprimées de nos systèmes.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >14. Devons-nous mettre à jour cette politique</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >En bref</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >:</span
            ></em
          ></strong
        ><strong
          ><em
            ><span
              style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
              >&nbsp;</span
            ></em
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></em
        ><em
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >Oui, nous mettrons à jour cette politique si nécessaire pour rester conforme
            aux lois en vigueur.</span
          ></em
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Nous pouvons mettre à jour cette politique de confidentialité de temps à autre.
          La version mise à jour sera indiquée par une date de mise à jour « Révisée » et
          la version mise à jour entrera en vigueur dès qu'elle sera accessible. Si nous
          apportons des modifications importantes à cette politique de confidentialité,
          nous pouvons vous en informer soit en affichant bien en vue un avis de ces
          modifications, soit en vous envoyant directement une notification. Nous vous
          encourageons à consulter fréquemment cette politique de confidentialité pour
          être informé de la manière dont nous protégeons vos informations.</span
        >
      </p>
      <p
        style="
          margin-top: 14pt;
          margin-bottom: 14pt;
          text-align: justify;
          line-height: normal;
          font-size: 15.5pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span style="font-family: Arial; color: #4f4e56"
            >15. COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CETTE POLITIQUE</span
          ></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">&nbsp;</span></strong
        ><strong><span style="font-family: Arial; color: #4f4e56">?</span></strong>
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Si vous avez des questions ou des commentaires sur cette politique, vous pouvez
          contacter notre délégué à la protection des données (DPO), Mr Félix TOSSOU, par
          e-mail à</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp; </span
        ><span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >directiongenerale</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >@</span
        ><span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >anpe</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >.bj</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >, ou par courrier à</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >:</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          class="Emphasis"
          style="
            font-family: Arial;
            font-size: 10.5pt;
            font-weight: bold;
            font-style: normal;
            color: #5f6368;
            background-color: #ffffff;
          "
          >Agence nationale pour l'Emploi</span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
        >
          (AnpE)</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Mr Félix TOSSOU</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Boîte postale </span
        ><span
          style="
            font-family: Arial;
            font-size: 10.5pt;
            color: #4d5156;
            background-color: #ffffff;
          "
          >10 BP 99 Cotonou</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 10.5pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #4d5156; background-color: #ffffff"
          >Aijedo, Cotonou, Bénin</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >COMMENT POUVEZ-VOUS CONSULTER, METTRE À JOUR OU SUPPRIMER LES DONNÉES QUE
            NOUS COLLECTONS CHEZ VOUS</span
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >&nbsp;</span
          ></strong
        ><strong
          ><span
            style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
            >?</span
          ></strong
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Selon les lois de certains pays, vous pouvez avoir le droit de demander l'accès
          aux informations personnelles que nous collectons auprès de vous, de modifier
          ces informations ou de les supprimer dans certaines circonstances. Pour demander
          la révision, la mise à jour ou la suppression de vos informations personnelles,
          veuillez soumettre un formulaire de demande en cliquant</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><a
          href="https://app.termly.io/notify/74fe8c26-c6ad-4994-a9ef-1fd00f6a4063"
          target="_blank"
          style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >ici</span
              ></u
            ></strong
          ></a
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >. Nous répondrons à votre demande dans un délai de 30 jours.</span
        >
      </p>
      <p
        style="
          margin-bottom: 15pt;
          text-align: justify;
          line-height: normal;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >Cette politique de confidentialité a été créée à l'aide</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><a
          href="https://termly.io/products/privacy-policy-generator/?ftseo"
          style="text-decoration: none"
          ><strong
            ><u
              ><span
                style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#008000"
                >du générateur de politique de confidentialité de Termly</span
              ></u
            ></strong
          ></a
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >&nbsp;</span
        ><span
          style="font-family:&#39;Times New Roman&#39;; letter-spacing:0.15pt; color:#4f4e56"
          >.</span
        >
      </p>
      <p style="text-align: justify">&nbsp;</p>
    </div>
    <p style="bottom: 10px; right: 10px; position: absolute"></p>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.body {
  line-height: 108%;
  font-family: Calibri;
  font-size: 11pt;
}
h1,
h3,
h4,
h5,
p {
  margin: 0pt 0pt 8pt;
}
li {
  margin-top: 0pt;
  margin-bottom: 8pt;
}
h1 {
  margin-top: 5pt;
  margin-bottom: 5pt;
  page-break-inside: auto;
  page-break-after: auto;
  line-height: normal;
  font-family: "Times New Roman";
  font-size: 24pt;
  font-weight: bold;
  color: #000000;
}
h3 {
  margin-top: 5pt;
  margin-bottom: 5pt;
  page-break-inside: auto;
  page-break-after: auto;
  line-height: normal;
  font-family: "Times New Roman";
  font-size: 13.5pt;
  font-weight: bold;
  color: #000000;
}
h4 {
  margin-top: 5pt;
  margin-bottom: 5pt;
  page-break-inside: auto;
  page-break-after: auto;
  line-height: normal;
  font-family: "Times New Roman";
  font-size: 12pt;
  font-weight: bold;
  font-style: normal;
  color: #000000;
}
h5 {
  margin-top: 2pt;
  margin-bottom: 0pt;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 108%;
  font-family: "Calibri Light";
  font-size: 11pt;
  font-weight: normal;
  color: #2e74b5;
}
.HTMLPreformatted {
  margin-bottom: 0pt;
  line-height: normal;
  font-family: "Courier New";
  font-size: 10pt;
}
.NoSpacing {
  margin-bottom: 0pt;
  line-height: normal;
  font-size: 11pt;
}
.visible {
  margin-top: 5pt;
  margin-bottom: 5pt;
  line-height: normal;
  font-family: "Times New Roman";
  font-size: 12pt;
}
span.Emphasis {
  font-style: italic;
}
span.Hyperlink {
  text-decoration: underline;
  color: #0000ff;
}
span.PrformatHTMLCar {
  font-family: "Courier New";
  font-size: 10pt;
}
span.Strong {
  font-weight: bold;
}
span.Titre1Car {
  font-family: "Times New Roman";
  font-size: 24pt;
  font-weight: bold;
}
span.Titre3Car {
  font-family: "Times New Roman";
  font-size: 13.5pt;
  font-weight: bold;
}
span.Titre4Car {
  font-family: "Times New Roman";
  font-size: 12pt;
  font-weight: bold;
}
span.Titre5Car {
  font-family: "Calibri Light";
  color: #2e74b5;
}
</style>
