<template>
  <PDialog
    :header="'Politique de confidentialite'"
    :modal="true"
    :visible.sync="display"
    :contentStyle="{ overflow: 'scroll' }"
    :containerStyle="{ width: '80vw', background: 'white' }"
  >
    <politique-confidentialite-content />
    <template #footer>
      <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text" />
      <PButton label="Ok" icon="pi pi-check" @click="save" autofocus />
    </template>
  </PDialog>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions } from "vuex";
import PolitiqueConfidentialiteContent from "./PolitiqueConfidentialiteContent.vue";

export default {
  components: {
    PolitiqueConfidentialiteContent,
  },
  props: {},
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      statutAcceptation: null,
      raisonRetraits: [],
      editableItem: {},
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    ...mapActions({}),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("cancel");
    },
    async save() {
      this.$emit("save", {
        ...this.editableItem,
      });
      this.hide();
    },
  },
};
</script>
